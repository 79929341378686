import React, { useState } from 'react';
import Link from 'next/link';
import DemoPopupModal from '@/components/LandingPage/DemoPopupModal';

import Image from 'next/image';
import { Dialog } from '@headlessui/react';
import FeatureSection1 from '@/components/LandingPage/FeatureSection1';
import CalloutSectionWithChat from '@/components/LandingPage/CalloutSectionWithChat';
import CalloutSection2 from '@/components/LandingPage/CalloutSection2';
import BannerSection from '@/components/LandingPage/BannerSection';
import Footer from '@/components/LandingPage/Footer';
import LogoSection from '@/components/LandingPage/LogoSection';
import TestimonialSection from '@/components/LandingPage/TestimonialSection';
import PricingSection from '@/components/LandingPage/PricingSection';
import TemplateSection from '@/components/LandingPage/TemplateSection';
import Script from 'next/script';
import { PlayCircleIcon } from '@heroicons/react/24/outline';
import CalloutSection from '@/components/LandingPage/CalloutSection';
import CalloutSection3 from '@/components/LandingPage/CalloutSection3';
import NewsletterSection from '@/components/LandingPage/NewsletterSection';

const Landing = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const navigation = [
    /* { name: 'Templates', href: '#template' },
    { name: 'Features', href: '#feature' },
    { name: 'Pricing', href: '#pricing' },*/
  ];

  return (
    <div className="w-full h-auto">
      {/* <Script
        src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=U2Ye7J"
        strategy="lazyOnload"
      /> */}
      {/* Banner Section
      <BannerSection
        url="/api/auth/signup?returnTo=/dashboard"
        cta="Register Now"
        message="Start building your app for free. Limited time only."
        header="Beta Launch"
      /> */}

      <BannerSection
        url="https://app.hubble.ai"
        cta="Access Here"
        message="Our original AI Essay Writing tool has moved."
        header="AI Essay Writer"
      />

      {/* Hero Section */}
      <div id="hero">
        <div className="px-6 pt-6 lg:px-8">
          <nav
            className="flex items-center justify-between"
            aria-label="Global"
          >
            <div className="flex lg:flex-1">
              <a href="#" className="-m-1.5 p-1.5">
                <span className="sr-only">Rubber</span>
                <Image
                  priority
                  src="/assets/icons/Logo.svg"
                  height={0}
                  width={100}
                  alt="Rubber Logo"
                />
              </a>
            </div>
            <div className="flex lg:hidden">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <div className="w-6 h-6 relative">
                  <Image
                    src="/assets/icons/HamburgerMenu.svg"
                    fill
                    alt="Mobile Menu Controller"
                  />
                </div>
              </button>
            </div>

            <div className="hidden lg:flex lg:flex-1 lg:justify-end cursor-pointer space-x-4">
              <Link
                href="/api/auth/login?returnTo=/dashboard"
                className="text-sm font-semibold leading-6 text-gray-900"
              >
                Log in
              </Link>
              <Link
                href="/api/auth/signup?returnTo=/dashboard"
                className="text-sm font-semibold leading-6 text-gray-900 hover:translate-x-1"
              >
                Register <span aria-hidden="true">&rarr;</span>
              </Link>
            </div>
          </nav>
          <Dialog as="div" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
            <Dialog.Panel
              focus="true"
              className="fixed inset-0 z-10 overflow-y-auto bg-white px-6 py-6 lg:hidden"
            >
              <div className="flex items-center justify-between">
                <a href="#" className="-m-1.5 p-1.5">
                  <span className="sr-only">Rubber.ai</span>
                  <img className="h-8" src="/assets/icons/Logo.svg" alt="" />
                </a>
                <button
                  type="button"
                  className="-m-2.5 rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="mt-6 flow-root">
                <div className="-my-6 divide-y divide-gray-500/10">
                  <div
                    onClick={() => setMobileMenuOpen(false)}
                    className="space-y-2 py-6"
                  >
                    {navigation?.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="-mx-3 block rounded-lg py-2 px-3 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-400/10"
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                  <div className="flex flex-col py-6">
                    <Link
                      href="/api/auth/login?returnTo=/dashboard"
                      className="font-semibold leading-6 text-gray-900"
                    >
                      Log in
                    </Link>

                    <Link
                      href="/api/auth/signup?returnTo=/dashboard"
                      className="font-semibold py-4 leading-6 text-gray-900"
                    >
                      Register <span aria-hidden="true">&rarr;</span>
                    </Link>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Dialog>
        </div>
        <main className="">
          <div className="flex px-12 lg:px-8">
            <div className="mx-auto">
              <div className="hidden sm:mb-8 sm:flex sm:justify-center"></div>
              <div className="text-center flex flex-col">
                <div className="items-center  pt-4 pb-4">
                  <div className="text-[14px] justify-center flex flex-row font-medium text-black py-2 gap-x-2 rounded-2xl">
                    <label className="border flex flex-row rounded-3xl shadow-sm px-5 py-3">
                      <Image
                        priority
                        src="/assets/images/google-logo.png"
                        height={20}
                        width={20}
                        alt="Google Logo"
                        className="absolute"
                      />

                      <p className=" ml-8">
                        Google PaLM 2 models are now available.
                      </p>
                    </label>
                  </div>
                </div>

                <h1 className="leading-normal font-bold text-gray-900 text-4xl">
                  Add AI to your website in minutes –<br></br>with no code.
                </h1>
                <p className="mt-6 mx-auto text-md sm:text-lg leading-normal text-gray-600">
                  Start shipping AI apps that move your business forward. Stop
                  pasting text into ChatGPT, <br></br>wrangling your data to
                  work with AI models, and cobbling tools together to use AI.
                </p>

                <div className="mt-10 flex items-center justify-center cursor-pointer">
                  <Link
                    href="/api/auth/signup?returnTo=/dashboard"
                    className="rounded-lg bg-[#2483E2] mr-6 px-5 py-2.5 text-sm font-medium leading-7 text-white hover:bg-[#489CF0] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Try Rubber for free →
                  </Link>

                  <DemoPopupModal
                    style="mr-2 text-sm font-medium cursor-pointer leading-7 text-gray-900"
                    cta={<PlayCircleIcon className="h-6"></PlayCircleIcon>}
                    url="https://www.loom.com/embed/63b7f139206b4eb2a8811dd5f2154fae"
                  />
                  <DemoPopupModal
                    style="text-sm font-medium cursor-pointer leading-7 text-gray-900"
                    cta="Watch Demo"
                    url="https://www.loom.com/embed/63b7f139206b4eb2a8811dd5f2154fae"
                  />
                </div>

                {/*<div className="hidden md:visible mt-10 w-auto md:flex items-center justify-center cursor-pointer">
                  <iframe
                    width="1000"
                    height="500"
                    src="https://www.loom.com/embed/63b7f139206b4eb2a8811dd5f2154fae"
                    className="w-full"
                  ></iframe>
                </div>
                <div className="md:hidden sm:visible mt-10 w-auto sm:flex items-center justify-center cursor-pointer">
                  <iframe
                    width="1000"
                    height="300"
                    src="https://www.loom.com/embed/63b7f139206b4eb2a8811dd5f2154fae"
                    className="w-full"
                  ></iframe>
            </div>*/}
              </div>
            </div>
          </div>
        </main>
      </div>

      {/* Callout Section */}
      <CalloutSectionWithChat />

      {/* Logo Section */}
      <LogoSection />

      {/* Feature Section */}
      <FeatureSection1 />

      {/* Callout Section */}
      <CalloutSection />

      {/* Feature Section */}
      <CalloutSection2 />

      {/* Feature Section */}
      <CalloutSection3 />

      {/* Example Section 
      <ExampleSection />*/}

      {/*Template Section */}
      <TemplateSection />

      {/* Pricing Section */}
      <PricingSection />

      {/* Testimonial Section */}
      <TestimonialSection />

      {/* CTA 
      <CTASection />*/}

      {/* Newsletter 
      <NewsletterSection />*/}

      {/* Footer */}
      <Footer />
    </div>
  );
};

export const getStaticProps = async () => {
  return { props: {}, revalidate: 600 };
};

export default Landing;
