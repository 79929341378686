import Image from 'next/image';

export default function LogoSection() {
  return (
    <div className="bg-white py-18 sm:py-20" id="logos">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-center text-md leading-8 text-gray-900">
          Built by a world-class team of Engineers, Product Managers and AI
          Researchers
        </h2>
        <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-4 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-4 lg:mx-0 lg:max-w-none lg:grid-cols-5">
          <Image
            priority
            className="col-span-2 max-h-8 w-full object-contain lg:col-span-1"
            src="/assets/images/amazon.png"
            height={0}
            width={100}
            alt="amazon"
          />
          <Image
            className="col-span-2 max-h-6 w-full object-contain lg:col-span-1"
            src="/assets/images/visa.png"
            alt="visa"
            height={0}
            width={100}
          />
          <Image
            className="col-span-2 max-h-10 w-full object-contain lg:col-span-1"
            src="/assets/images/iit-bomby.png"
            alt="iit"
            height={0}
            width={120}
          />
          <Image
            className="col-span-2 max-h-10 w-full object-contain lg:col-span-1"
            src="/assets/images/wayfair.png"
            alt="visa"
            height={0}
            width={120}
          />
          <Image
            className="col-span-2 max-h-8 w-full object-contain lg:col-span-1"
            src="/assets/images/ucsd.png"
            alt="ucsd"
            height={0}
            width={100}
          />
        </div>
      </div>
    </div>
  );
}
