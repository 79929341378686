import * as Tabs from '@radix-ui/react-tabs';
import Link from 'next/link';
import Image from 'next/image';
import EmojiPicker, {
  EmojiStyle,
  SkinTones,
  Theme,
  Categories,
  EmojiClickData,
  Emoji,
  SuggestionMode,
  SkinTonePickerLocation,
} from 'emoji-picker-react';

const TemplateSection = () => (
  <div className="bg-white py-18 sm:py-20">
    <div className="mx-auto max-w-7xl px-6 lg:px-8">
      <h2 className="justify-center pb-2 text-center text-md font-semibold leading-8 tracking-tight text-blue-600">
        Grow with AI
      </h2>
      <h1 className="text-center text-4xl pb-4 font-semibold leading-tight text-gray-900">
        30+ app templates ready to launch
      </h1>
      <p className="mt-1 text-center text-lg leading-8 text-gray-600">
        Strapped for time? We have done the heavy lifting for you by creating
        professionally-designed, conversion-optimized app templates.
      </p>
      <div className="justify-center flex flex-row mt-6 pb-8">
        <Link
          href="/featured"
          className="cursor-pointer rounded-lg bg-[#2483E2] mr-6 px-5 py-2.5 text-sm font-medium leading-7 text-white hover:bg-[#489CF0] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Start with a Template
        </Link>
      </div>

      <Tabs.Root
        className="mx-auto"
        defaultValue="tab1"
        orientation="horizontal"
      >
        <div className="justify-center flex flex-row pb-3 mb-5 border-b">
          <Tabs.List
            aria-label="tabs example"
            className="cursor-pointer font-medium text-[#575757] text-center justify-center flex gap-x-10"
          >
            <Tabs.Trigger
              value="tab1"
              className="data-[state=active]:focus:text-black"
            >
              Marketing
            </Tabs.Trigger>
            <Tabs.Trigger
              value="tab2"
              className="data-[state=active]:focus:text-black"
            >
              Sales
            </Tabs.Trigger>
            <Tabs.Trigger
              value="tab3"
              className="data-[state=active]:focus:text-black"
            >
              Business
            </Tabs.Trigger>
            <Tabs.Trigger
              value="tab4"
              className="data-[state=active]:focus:text-black hidden sm:block"
            >
              Professional
            </Tabs.Trigger>
            <Tabs.Trigger
              value="tab5"
              className="data-[state=active]:focus:text-black"
            >
              Content
            </Tabs.Trigger>
            <Tabs.Trigger
              value="tab6"
              className="data-[state=active]:focus:text-black"
            >
              Education
            </Tabs.Trigger>
            {/*<Tabs.Trigger value="tab4" className="data-[state=active]:focus:text-black">Customer Service</Tabs.Trigger>*/}
          </Tabs.List>
        </div>
        <Tabs.Content value="tab1" className="flex flex-col gap-y-4">
          <div className="grid md:grid-cols-3 gap-y-3 justify-center gap-x-4">
            <div className="col-span-1 border hover:-translate-y-1 cursor-pointer bg-gray-300/5 shadow-md rounded-md px-4 py-6 max-w-xl items-start justify-between">
              <Link href="/apps/0f8e0723e2ffc7ef254c8aab7719b28d">
                <div className="mx-4 h-12 w-12 bg-gray-200 rounded-lg flex items-center justify-center">
                  <Emoji unified="1f50e" size="25" className="" />
                </div>
                <div className="flex flex-row pt-2">
                  <div className="flex flex-row">
                    <div className="flex flex-col pl-4">
                      <h2>AI SEO Assistant</h2>
                      <p className="text-sm pt-2 text-gray-400">
                        {`Find the best keywords and receive content ideas to write about to boost your ranking.`}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-span-1 border hover:-translate-y-1 cursor-pointer bg-gray-300/5 shadow-md rounded-md px-4 py-6 max-w-xl items-start justify-between">
              <Link href="/apps/8730a22e462394513ac54fb6c4c9690c">
                <div className="mx-4 h-12 w-12 bg-gray-200 rounded-lg flex items-center justify-center">
                  <Emoji unified="1f4e5" size="25" className="" />
                </div>
                <div className="flex flex-row pt-2">
                  <div className="flex flex-row">
                    <div className="flex flex-col pl-4">
                      <h2>AI Email Marketer</h2>
                      <p className="text-sm pt-2 text-gray-400">
                        {`Have an AI email marketer build out your entire email campaign.`}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-span-1 border hover:-translate-y-1 cursor-pointer bg-gray-300/5 shadow-md rounded-md px-4 py-6 max-w-xl items-start justify-between">
              <Link href="/apps/94c32a038ad8ca0358c5b4b45121a754">
                <div className="mx-4 h-12 w-12 bg-gray-200 rounded-lg flex items-center justify-center">
                  <Emoji unified="1f913" size="25" className="" />
                </div>
                <div className="flex flex-row pt-2">
                  <div className="flex flex-row">
                    <div className="flex flex-col pl-4">
                      <h2>AI PPC Marketer</h2>
                      <p className="text-sm pt-2 text-gray-400">
                        {`Generate engaging headlines, keyword variations, a complete PPC campaign structure.`}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="grid md:grid-cols-3 gap-y-3 justify-center gap-x-4">
            <div className="col-span-1 border hover:-translate-y-1 cursor-pointer bg-gray-300/5 shadow-md rounded-md px-4 py-6 max-w-xl items-start justify-between">
              <Link href="/apps/39e546553a8c4270021919470021f31b">
                <div className="mx-4 h-12 w-12 bg-gray-200 rounded-lg flex items-center justify-center">
                  <Emoji unified="1f680" size="25" className="" />
                </div>
                <div className="flex flex-row pt-2">
                  <div className="flex flex-row">
                    <div className="flex flex-col pl-4">
                      <h2>AI Product Marketer</h2>
                      <p className="text-sm pt-2 text-gray-400">
                        {`An AI product marketer that'll help launch your next product or feature.`}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-span-1 border hover:-translate-y-1 cursor-pointer bg-gray-300/5 shadow-md rounded-md px-4 py-6 max-w-xl items-start justify-between">
              <Link href="apps/5630eb3b62c6f01a3ce293cdb7626569">
                <div className="mx-4 h-12 w-12 bg-gray-200 rounded-lg flex items-center justify-center">
                  <Emoji unified="2728" size="25" className="" />
                </div>

                <div className="flex flex-row pt-2">
                  <div className="flex flex-row">
                    <div className="flex flex-col pl-4">
                      <h2>AI Branding Creator</h2>
                      <p className="text-sm pt-2 text-gray-400">
                        {`Create a complete brand identity based on the product, location, and target clients.`}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-span-1 border hover:-translate-y-1 cursor-pointer bg-gray-300/5 shadow-md rounded-md px-4 py-6 max-w-xl items-start justify-between">
              <Link href="/apps/a5e20e228af4d6f17d17cf21f255eb6d">
                <div className="mx-4 h-12 w-12 bg-gray-200 rounded-lg flex items-center justify-center">
                  <Emoji unified="1f6d2" size="25" className="" />
                </div>
                <div className="flex flex-row pt-2">
                  <div className="flex flex-row">
                    <div className="flex flex-col pl-4">
                      <h2>Omnichannel Campaign Builder</h2>
                      <p className="text-sm pt-2 text-gray-400">
                        {`Design marketing campaigns for multiple channels.`}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="grid md:grid-cols-3 gap-y-3 justify-center gap-x-4">
            <div className="col-span-1 border hover:-translate-y-1 cursor-pointer bg-gray-300/5 shadow-md rounded-md px-4 py-6 max-w-xl items-start justify-between">
              <Link href="/apps/6184a217d63a013bddc7c0038725f87b">
                <div className="mx-4 h-12 w-12 bg-gray-200 rounded-lg flex items-center justify-center">
                  <Emoji unified="1f4c5" size="25" className="" />
                </div>
                <div className="flex flex-row pt-2">
                  <div className="flex flex-row">
                    <div className="flex flex-col pl-4">
                      <h2>Social Media Campaign Planner</h2>
                      <p className="text-sm pt-2 text-gray-400">
                        {`Create a month-long social media campaign calendar in minutes.`}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-span-1 border hover:-translate-y-1 cursor-pointer bg-gray-300/5 shadow-md rounded-md px-4 py-6 max-w-xl items-start justify-between">
              <Link href="/apps/f2111a2bfc979bb5b3e53e8f54b6791b">
                <div className="mx-4 h-12 w-12 bg-gray-200 rounded-lg flex items-center justify-center">
                  <Emoji unified="1f4f6" size="25" className="" />
                </div>
                <div className="flex flex-row pt-2">
                  <div className="flex flex-row">
                    <div className="flex flex-col pl-4">
                      <h2>Ad Campaign Manager</h2>
                      <p className="text-sm pt-2 text-gray-400">
                        {`Create high-converting ad campaigns to boost your brand.`}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </Tabs.Content>
        <Tabs.Content value="tab2" className="flex flex-col gap-y-4">
          <div className="grid md:grid-cols-3 gap-y-3 justify-center gap-x-4">
            <div className="col-span-1 border hover:-translate-y-1 cursor-pointer bg-gray-300/5 shadow-md rounded-md px-4 py-6 max-w-xl items-start justify-between">
              <Link href="/apps/d97a8a43efea1987f836c3a6b7f7b787">
                <div className="mx-4 h-12 w-12 bg-gray-200 rounded-lg flex items-center justify-center">
                  <Emoji unified="1f4b5" size="25" className="" />
                </div>
                <div className="flex flex-row pt-2">
                  <div className="flex flex-row">
                    <div className="flex flex-col pl-4">
                      <h2>AI Sales Writer</h2>
                      <p className="text-sm pt-2 text-gray-400">
                        {`Convert more leads into customers with tailored sales pitches every time.`}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-span-1 border hover:-translate-y-1 cursor-pointer bg-gray-300/5 shadow-md rounded-md px-4 py-6 max-w-xl items-start justify-between">
              <Link href="/apps/e6f668bfb272e305132acdf4c6e9a1a2">
                <div className="mx-4 h-12 w-12 bg-gray-200 rounded-lg flex items-center justify-center">
                  <Emoji unified="1f377" size="25" className="" />
                </div>

                <div className="flex flex-row pt-2">
                  <div className="flex flex-row">
                    <div className="flex flex-col pl-4">
                      <h2>AI Wine Recommendations</h2>
                      <p className="text-sm pt-2 text-gray-400">
                        {`Generate high-intent email leads for your business via offering an AI-powered survey.`}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-span-1 border hover:-translate-y-1 cursor-pointer bg-gray-300/5 shadow-md rounded-md px-4 py-6 max-w-xl items-start justify-between">
              <Link href="/apps/15a14710cbfa4c02b2d5de2a559d244f">
                <div className="mx-4 h-12 w-12 bg-gray-200 rounded-lg flex items-center justify-center">
                  <Emoji unified="1f334" size="25" className="" />
                </div>

                <div className="flex flex-row pt-2">
                  <div className="flex flex-row">
                    <div className="flex flex-col pl-4">
                      <h2>Couples Vacation Planner</h2>
                      <p className="text-sm pt-2 text-gray-400">
                        {`Capture qualified leads for your vacation travel agency.`}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>

          <div className="grid md:grid-cols-3 gap-y-3 justify-center gap-x-4">
            <div className="col-span-1 border hover:-translate-y-1 cursor-pointer bg-gray-300/5 shadow-md rounded-md px-4 py-6 max-w-xl items-start justify-between">
              <Link href="/apps/81653fd1050f77c4633600deac7c461e">
                <div className="mx-4 h-12 w-12 bg-gray-200 rounded-lg flex items-center justify-center">
                  <Emoji unified="1f4d4" size="25" className="" />
                </div>

                <div className="flex flex-row pt-2">
                  <div className="flex flex-row">
                    <div className="flex flex-col pl-4">
                      <h2>Case Study Generator</h2>
                      <p className="text-sm pt-2 text-gray-400">
                        {`Submit a marketing brief, generate a full case study.`}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-span-1 border hover:-translate-y-1 cursor-pointer bg-gray-300/5 shadow-md rounded-md px-4 py-6 max-w-xl items-start justify-between">
              <Link href="/apps/618bd7ee9f56be9f3fbe644963a8cb2e">
                <div className="mx-4 h-12 w-12 bg-gray-200 rounded-lg flex items-center justify-center">
                  <Emoji unified="1f468" size="25" className="" />
                </div>

                <div className="flex flex-row pt-2">
                  <div className="flex flex-row">
                    <div className="flex flex-col pl-4">
                      <h2>{`What's Cookin'`}</h2>
                      <p className="text-sm pt-2 text-gray-400">
                        {`Generate leads and mouthwatering recipes using ingredients you have with AI.`}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-span-1 border hover:-translate-y-1 cursor-pointer bg-gray-300/5 shadow-md rounded-md px-4 py-6 max-w-xl items-start justify-between">
              <Link href="/apps/d066629694f3cb0269654d87e7f798ce">
                <div className="mx-4 h-12 w-12 bg-gray-200 rounded-lg flex items-center justify-center">
                  <Emoji unified="1f4b0" size="25" className="" />
                </div>

                <div className="flex flex-row pt-2">
                  <div className="flex flex-row">
                    <div className="flex flex-col pl-4">
                      <h2>Product Pitch Generator</h2>
                      <p className="text-sm pt-2 text-gray-400">
                        {`Create the perfect product pitch each time with the help of AI.`}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </Tabs.Content>
        <Tabs.Content value="tab3" className="flex flex-col gap-y-4">
          <div className="grid md:grid-cols-3 gap-y-3 justify-center gap-x-4">
            <div className="col-span-1 border hover:-translate-y-1 cursor-pointer bg-gray-300/5 shadow-md rounded-md px-4 py-6 max-w-xl items-start justify-between">
              <Link href="/apps/9220a9a7d861c5343f44bdad161d2df6">
                <div className="mx-4 h-12 w-12 bg-gray-200 rounded-lg flex items-center justify-center">
                  <Emoji unified="1f93a" size="25" className="" />
                </div>

                <div className="flex flex-row pt-2">
                  <div className="flex flex-row">
                    <div className="flex flex-col pl-4">
                      <h2>Competitor Analysis</h2>
                      <p className="text-sm pt-2 text-gray-400">
                        {`Get a competitive analysis report without having to hire a McKinsey consultant.`}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-span-1 border hover:-translate-y-1 cursor-pointer bg-gray-300/5 shadow-md rounded-md px-4 py-6 max-w-xl items-start justify-between">
              <Link href="/apps/80b9d6f70d8536a9e6b560cf9bc8eb46">
                <div className="mx-4 h-12 w-12 bg-gray-200 rounded-lg flex items-center justify-center">
                  <Emoji unified="1f4da" size="25" className="" />
                </div>

                <div className="flex flex-row pt-2">
                  <div className="flex flex-row">
                    <div className="flex flex-col pl-4">
                      <h2>AI Business School Professor</h2>
                      <p className="text-sm pt-2 text-gray-400">
                        {`Cheaper than Business School. Uses the book "The Innovator's Dilemma" as a data source.`}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-span-1 border hover:-translate-y-1 cursor-pointer bg-gray-300/5 shadow-md rounded-md px-4 py-6 max-w-xl items-start justify-between">
              <Link href="/apps/f00cc1080d7074969b8697cd34250450">
                <div className="mx-4 h-12 w-12 bg-gray-200 rounded-lg flex items-center justify-center">
                  <Emoji unified="1f9f0" size="25" className="" />
                </div>

                <div className="flex flex-row pt-2">
                  <div className="flex flex-row">
                    <div className="flex flex-col pl-4">
                      <h2>Troubleshooting Guide Creator</h2>
                      <p className="text-sm pt-2 text-gray-400">
                        {`Easily create content to troubleshoot common issues encountered by your customers.`}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="grid md:grid-cols-3 gap-y-3 justify-center gap-x-4">
            <div className="col-span-1 border hover:-translate-y-1 cursor-pointer bg-gray-300/5 shadow-md rounded-md px-4 py-6 max-w-xl items-start justify-between">
              <Link href="/apps/c3ef305b56e004471e340b0884164114">
                <div className="mx-4 h-12 w-12 bg-gray-200 rounded-lg flex items-center justify-center">
                  <Emoji unified="1f9e0" size="25" className="" />
                </div>

                <div className="flex flex-row pt-2">
                  <div className="flex flex-row">
                    <div className="flex flex-col pl-4">
                      <h2>Terms and Conditions Bot</h2>
                      <p className="text-sm pt-2 text-gray-400">
                        {`Ask any question regarding OpenAI's Terms & Services.`}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-span-1 border hover:-translate-y-1 cursor-pointer bg-gray-300/5 shadow-md rounded-md px-4 py-6 max-w-xl items-start justify-between">
              <Link href="/apps/cbcf5d6d5236f8dedac7c4e1ed49bbd5">
                <div className="mx-4 h-12 w-12 bg-gray-200 rounded-lg flex items-center justify-center">
                  <Emoji unified="1f4af" size="25" className="" />
                </div>

                <div className="flex flex-row pt-2">
                  <div className="flex flex-row">
                    <div className="flex flex-col pl-4">
                      <h2>AI Feedback Analyst</h2>
                      <p className="text-sm pt-2 text-gray-400">
                        {`Analyze user feedback in order to provide solutions and improve customer satisfaction.`}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            {/*</div>
          <div className="grid md:grid-cols-3 gap-y-3 justify-center gap-x-4">*/}
          </div>
        </Tabs.Content>
        <Tabs.Content value="tab4" className="flex flex-col gap-y-4">
          <div className="grid md:grid-cols-3 gap-y-3 justify-center gap-x-4">
            <div className="col-span-1 border hover:-translate-y-1 cursor-pointer bg-gray-300/5 shadow-md rounded-md px-4 py-6 max-w-xl items-start justify-between">
              <Link href="/apps/926a82aa463fe2aa64eee1e07b9c3160">
                <div className="mx-4 h-12 w-12 bg-gray-200 rounded-lg flex items-center justify-center">
                  <Emoji unified="1f423" size="25" className="" />
                </div>

                <div className="flex flex-row pt-2">
                  <div className="flex flex-row">
                    <div className="flex flex-col pl-4">
                      <h2>AI Tweet Writer</h2>
                      <p className="text-sm pt-2 text-gray-400">
                        {`Auto-generate tweets to engage and inform your followers.`}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-span-1 border hover:-translate-y-1 cursor-pointer bg-gray-300/5 shadow-md rounded-md px-4 py-6 max-w-xl items-start justify-between">
              <Link href="/apps/a60a978f40bd042f3ae94844355ee712">
                <div className="mx-4 h-12 w-12 bg-gray-200 rounded-lg flex items-center justify-center">
                  <Emoji unified="1f91d" size="25" className="" />
                </div>
                <div className="flex flex-row pt-2">
                  <div className="flex flex-row">
                    <div className="flex flex-col pl-4">
                      <h2>LinkedIn Post Generator</h2>
                      <p className="text-sm pt-2 text-gray-400">
                        {`Have AI write all your LinkedIn posts so you can focus on being an influencer.`}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-span-1 border hover:-translate-y-1 cursor-pointer bg-gray-300/5 shadow-md rounded-md px-4 py-6 max-w-xl items-start justify-between">
              <Link href="/apps/290b40409a881f9d74adae0299e39079">
                <div className="mx-4 h-12 w-12 bg-gray-200 rounded-lg flex items-center justify-center">
                  <Emoji unified="1f4dd" size="25" className="" />
                </div>
                <div className="flex flex-row pt-2">
                  <div className="flex flex-row">
                    <div className="flex flex-col pl-4">
                      <h2>Cover Letter Generator</h2>
                      <p className="text-sm pt-2 text-gray-400">
                        {`Supercharge your job search. Generate customized cover letters with AI.`}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-span-1 border hover:-translate-y-1 cursor-pointer bg-gray-300/5 shadow-md rounded-md px-4 py-6 max-w-xl items-start justify-between">
              <Link href="/apps/97eb68e27f2fd2062be00fd6c76ab69c">
                <div className="mx-4 h-12 w-12 bg-gray-200 rounded-lg flex items-center justify-center">
                  <Emoji unified="2b50" size="25" className="" />
                </div>
                <div className="flex flex-row pt-2">
                  <div className="flex flex-row">
                    <div className="flex flex-col pl-4">
                      <h2>Letter of Recommendation</h2>
                      <p className="text-sm pt-2 text-gray-400">
                        {`Make writing personalized recommendation letters for your colleagues, reports, and friends easy.`}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-span-1 border hover:-translate-y-1 cursor-pointer bg-gray-300/5 shadow-md rounded-md px-4 py-6 max-w-xl items-start justify-between">
              <Link href="/apps/bacb6ec17ea8505ee638fe43bc6301ed">
                <div className="mx-4 h-12 w-12 bg-gray-200 rounded-lg flex items-center justify-center">
                  <Emoji unified="1fa7a" size="25" className="" />
                </div>
                <div className="flex flex-row pt-2">
                  <div className="flex flex-row">
                    <div className="flex flex-col pl-4">
                      <h2>Medical Summary Creator</h2>
                      <p className="text-sm pt-2 text-gray-400">
                        {`This app will create a medical summary based on provided patient information.`}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </Tabs.Content>
        <Tabs.Content value="tab5" className="flex flex-col gap-y-4">
          <div className="grid md:grid-cols-3 gap-y-3 justify-center gap-x-4">
            <div className="col-span-1 border hover:-translate-y-1 cursor-pointer bg-gray-300/5 shadow-md rounded-md px-4 py-6 max-w-xl items-start justify-between">
              <Link href="/apps/1c1d552db87624eedf5ce46943aa264a">
                <div className="mx-4 h-12 w-12 bg-gray-200 rounded-lg flex items-center justify-center">
                  <Emoji unified="1f3a5" size="25" className="" />
                </div>
                <div className="flex flex-row pt-2">
                  <div className="flex flex-row">
                    <div className="flex flex-col pl-4">
                      <h2>Video Script Creator</h2>
                      <p className="text-sm pt-2 text-gray-400">
                        {`Create a video script that can be used especially for marketing.`}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-span-1 border hover:-translate-y-1 cursor-pointer bg-gray-300/5 shadow-md rounded-md px-4 py-6 max-w-xl items-start justify-between">
              <Link href="/apps/9c3c2a6fabaa5473254e07fc2790650f">
                <div className="mx-4 h-12 w-12 bg-gray-200 rounded-lg flex items-center justify-center">
                  <Emoji unified="1f464" size="25" className="" />
                </div>
                <div className="flex flex-row pt-2">
                  <div className="flex flex-row">
                    <div className="flex flex-col pl-4">
                      <h2>{`Live Q&A Discussion Points`}</h2>
                      <p className="text-sm pt-2 text-gray-400">
                        {`Engage with your audience using high-quality, thought-provoking points during a Q&A sessions!`}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-span-1 border hover:-translate-y-1 cursor-pointer bg-gray-300/5 shadow-md rounded-md px-4 py-6 max-w-xl items-start justify-between">
              <Link href="/apps/4790eaa131c1f85e04134ac6af652956">
                <div className="mx-4 h-12 w-12 bg-gray-200 rounded-lg flex items-center justify-center">
                  <Emoji unified="1f4a1" size="25" className="" />
                </div>
                <div className="flex flex-row pt-2">
                  <div className="flex flex-row">
                    <div className="flex flex-col pl-4">
                      <h2>Blog Post Idea Generator</h2>
                      <p className="text-sm pt-2 text-gray-400">
                        {`Find interesting and attention-catching ideas for blog posts that match your topic.`}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-span-1 border hover:-translate-y-1 cursor-pointer bg-gray-300/5 shadow-md rounded-md px-4 py-6 max-w-xl items-start justify-between">
              <Link href="/apps/c9a394bd0c9e43937668ec759a23266c">
                <div className="mx-4 h-12 w-12 bg-gray-200 rounded-lg flex items-center justify-center">
                  <Emoji unified="27b0" size="25" className="" />
                </div>
                <div className="flex flex-row pt-2">
                  <div className="flex flex-row">
                    <div className="flex flex-col pl-4">
                      <h2>Content Amplification Strategist</h2>
                      <p className="text-sm pt-2 text-gray-400">
                        {`Drive traffic with great content amplification tactics and strategies.`}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-span-1 border hover:-translate-y-1 cursor-pointer bg-gray-300/5 shadow-md rounded-md px-4 py-6 max-w-xl items-start justify-between">
              <Link href="apps/d0599168cc81bacbaa5b4a1a463a2e8a">
                <div className="mx-4 h-12 w-12 bg-gray-200 rounded-lg flex items-center justify-center">
                  <Emoji unified="1f933" size="25" className="" />
                </div>
                <div className="flex flex-row pt-2">
                  <div className="flex flex-row">
                    <div className="flex flex-col pl-4">
                      <h2>UGC Script Writer</h2>
                      <p className="text-sm pt-2 text-gray-400">
                        {`Create compelling scripts for your brand's content creators.`}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-span-1 border hover:-translate-y-1 cursor-pointer bg-gray-300/5 shadow-md rounded-md px-4 py-6 max-w-xl items-start justify-between">
              <Link href="/apps/3c4811bf931ac79384ca473f3df81d89">
                <div className="mx-4 h-12 w-12 bg-gray-200 rounded-lg flex items-center justify-center">
                  <Emoji unified="1f4e3" size="25" className="" />
                </div>

                <div className="flex flex-row pt-2">
                  <div className="flex flex-row">
                    <div className="flex flex-col pl-4">
                      <h2>Press Release Generator</h2>
                      <p className="text-sm pt-2 text-gray-400">
                        {`Let AI help you craft the perfect press release each time.`}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </Tabs.Content>
        <Tabs.Content value="tab6" className="flex flex-col gap-y-4">
          <div className="grid md:grid-cols-3 gap-y-3 justify-center gap-x-4">
            <div className="col-span-1 border hover:-translate-y-1 cursor-pointer bg-gray-300/5 shadow-md rounded-md px-4 py-6 max-w-xl items-start justify-between">
              <Link href="/apps/e55536be7b887966c48e837e50fa811c">
                <div className="mx-4 h-12 w-12 bg-gray-200 rounded-lg flex items-center justify-center">
                  <Emoji unified="1f4ce" size="25" className="" />
                </div>
                <div className="flex flex-row pt-2">
                  <div className="flex flex-row">
                    <div className="flex flex-col pl-4">
                      <h2>Syllabus Outline Creator</h2>
                      <p className="text-sm pt-2 text-gray-400">
                        {`Leverage AI to create, organize, and structure course content.`}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-span-1 border hover:-translate-y-1 cursor-pointer bg-gray-300/5 shadow-md rounded-md px-4 py-6 max-w-xl items-start justify-between">
              <Link href="/apps/2dbad221d1c68edc4a2a1c10050e3fd8">
                <div className="mx-4 h-12 w-12 bg-gray-200 rounded-lg flex items-center justify-center">
                  <Emoji unified="1f4c6" size="25" className="" />
                </div>
                <div className="flex flex-row pt-2">
                  <div className="flex flex-row">
                    <div className="flex flex-col pl-4">
                      <h2>{`Language Learning Scheduler`}</h2>
                      <p className="text-sm pt-2 text-gray-400">
                        {`Plan a schedule based on proficiency level to learn a lesson.`}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-span-1 border hover:-translate-y-1 cursor-pointer bg-gray-300/5 shadow-md rounded-md px-4 py-6 max-w-xl items-start justify-between">
              <Link href="/apps/9a0b5f404e8dd2426609fdff3b8fa86e">
                <div className="mx-4 h-12 w-12 bg-gray-200 rounded-lg flex items-center justify-center">
                  <Emoji unified="1f4c3" size="25" className="" />
                </div>
                <div className="flex flex-row pt-2">
                  <div className="flex flex-row">
                    <div className="flex flex-col pl-4">
                      <h2>Term Paper Assistant</h2>
                      <p className="text-sm pt-2 text-gray-400">
                        {`Assistant to help write a research paper`}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-span-1 border hover:-translate-y-1 cursor-pointer bg-gray-300/5 shadow-md rounded-md px-4 py-6 max-w-xl items-start justify-between">
              <Link href="/apps/23701f4347efd8842a00c6ab0a109cbe">
                <div className="mx-4 h-12 w-12 bg-gray-200 rounded-lg flex items-center justify-center">
                  <Emoji unified="1f4d6" size="25" className="" />
                </div>
                <div className="flex flex-row pt-2">
                  <div className="flex flex-row">
                    <div className="flex flex-col pl-4">
                      <h2>Reading Passage Generator</h2>
                      <p className="text-sm pt-2 text-gray-400">
                        {`Generate reading passages and questions for your students to answer.`}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-span-1 border hover:-translate-y-1 cursor-pointer bg-gray-300/5 shadow-md rounded-md px-4 py-6 max-w-xl items-start justify-between">
              <Link href="apps/aa12ec99bcc1cb1b15712ef9a55118d6">
                <div className="mx-4 h-12 w-12 bg-gray-200 rounded-lg flex items-center justify-center">
                  <Emoji unified="1f4dd" size="25" className="" />
                </div>
                <div className="flex flex-row pt-2">
                  <div className="flex flex-row">
                    <div className="flex flex-col pl-4">
                      <h2>Note Creator</h2>
                      <p className="text-sm pt-2 text-gray-400">
                        {`Write notes and letters addressed to parents, co-teachers, or leaders.`}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-span-1 border hover:-translate-y-1 cursor-pointer bg-gray-300/5 shadow-md rounded-md px-4 py-6 max-w-xl items-start justify-between">
              <Link href="/apps/ee0544213a7fd37ff3b9c208e3453076">
                <div className="mx-4 h-12 w-12 bg-gray-200 rounded-lg flex items-center justify-center">
                  <Emoji unified="1f4ac" size="25" className="" />
                </div>

                <div className="flex flex-row pt-2">
                  <div className="flex flex-row">
                    <div className="flex flex-col pl-4">
                      <h2>Vocabulary Builder</h2>
                      <p className="text-sm pt-2 text-gray-400">
                        {`Instantly generate 5 advanced words For your students.`}
                      </p>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </Tabs.Content>
      </Tabs.Root>
    </div>
  </div>
);

export default TemplateSection;
