import React from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { Cross2Icon } from '@radix-ui/react-icons';
import Image from 'next/image';

const DemoPopupModal = ({ style, cta, icon, url }) => (
  <Dialog.Root>
    <Dialog.Trigger asChild>
      <button className={style}>
        <span>{icon}</span>
        {cta}
      </button>
    </Dialog.Trigger>
    <Dialog.Portal className="">
      <Dialog.Overlay className="DialogOverlay" />
      <Dialog.Content className="DialogContent w-6/12">
        <Dialog.Title className="font-semibold text-lg pl-4 pt-4">
          Watch Demo Recording
        </Dialog.Title>
        <Dialog.Description className="text-xs pt-2 pb-4 justify-center items-center">
          <iframe src={url} className="h-96 w-full"></iframe>

          {/* <iframe width="1000" height="400" src="https://demo.arcade.software/nX4FUdPfbJnjHswFEZlE?embed" className="w-full"></iframe> */}
        </Dialog.Description>

        <Dialog.Close asChild>
          <button className="IconButton -mt-2" aria-label="Close">
            <Cross2Icon />
          </button>
        </Dialog.Close>
      </Dialog.Content>
    </Dialog.Portal>
  </Dialog.Root>
);

export default DemoPopupModal;
