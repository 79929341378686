import { CheckIcon } from '@heroicons/react/24/outline';
import Image from 'next/image';
import Link from 'next/link';
import EmojiPicker, {
  EmojiStyle,
  SkinTones,
  Theme,
  Categories,
  EmojiClickData,
  Emoji,
  SuggestionMode,
  SkinTonePickerLocation,
} from 'emoji-picker-react';

const PlanFeatureItem = ({ entry }) => {
  return (
    <p className="flex flex-row py-1 text-body-color text-base leading-2 space-x-2">
      {/*<CheckIcon className="mr-1 h-[20px] w-[20px] text-blue-500" />*/}
      <span className="relative h-10 w-10 lg:h-6 lg:w-6 px-2 mr-2">
        <Image src="/assets/icons/CheckIcon.svg" alt="Check Icon" fill={true} />
      </span>
      {entry}
    </p>
  );
};

const PlanCard = ({ children }) => {
  return (
    <div className="col-span-6 lg:col-span-2 flex flex-col h-full">
      <div className="flex flex-col border-primary h-full rounded-3xl shadow-pricing relative z-10 border border-opacity-20 py-10 px-8 lg:py-10 lg:px-6 xl:p-12 ">
        {children}
      </div>
    </div>
  );
};

export default function PricingSection() {
  return (
    <section
      id="pricing"
      className="mx-6 md:mx-0 relative z-20 overflow-hidden bg-white pt-10 pb-12 lg:pt-[120px] lg:pb-[10px]"
    >
      <div className="container mx-auto">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="mx-auto mb-[60px] max-w-[510px] text-center lg:mb-20">
              <span className="text-[#2483E2] text-primary mb-2 block text-md font-semibold">
                Pricing Plan
              </span>
              <h2 className=" mb-4 text-3xl font-semibold sm:text-4xl leading-normal">
                Simple, transparent pricing.
              </h2>
            </div>
          </div>
        </div>

        <div className="DialogDescription grid grid-cols-6 gap-x-6 gap-y-4 h-full pb-14">
          <PlanCard>
            <div className="flex flex-col grow">
              <span className="flex flew-row text-primary mb-4 text-[#2D3C73] lg:text-lg font-semibold items-center">
                <div className="h-6 w-6 mr-2 relative">
                  <Image
                    src="/assets/icons/SmileFaceIcon.svg"
                    alt="Free Plan Icon"
                    fill
                  />
                </div>
                Personal
              </span>
              <p className="text-gray-500 border-[#F2F2F2] pb-4 text-base">
                Perfect for personal or non-commercial uses.
              </p>
              <h2 className="text-dark py-4 text-3xl lg:text-[42px] font-medium">
                $0
                <span className="text-body-color text-base font-medium text-gray-600">
                  {' '}
                  / month
                </span>
              </h2>

              <div className="text-gray-600 pt-4 pb-9">
                <PlanFeatureItem entry="Unlimited apps" available={true} />
                <PlanFeatureItem entry="50 entries/month" available={true} />
                <PlanFeatureItem
                  entry="1 custom data source"
                  available={false}
                />
                <PlanFeatureItem
                  entry="Embed apps anywhere"
                  available={false}
                />
              </div>
            </div>

            <Link
              href="/api/auth/signup?returnTo=/dashboard"
              className="mt-4 w-full text-center h-12 rounded-lg cursor-pointer hover:bg-[#489CF0] bg-[#2483E2] text-white flex items-center justify-center"
            >
              Start for Free
            </Link>
          </PlanCard>

          <PlanCard>
            <div className="flex flex-col grow">
              <span className="flex flex-row text-primary mb-4 text-blue-500 text-lg font-semibold items-center">
                <div className="h-6 w-6 mr-2 relative">
                  <Image
                    src="/assets/icons/ProPlan.svg"
                    alt="Pro Plan Icon"
                    fill
                  />
                </div>
                Pro
              </span>
              <p className="text-gray-500 border-[#F2F2F2] pb-4 text-base">
                Perfect for client projects or small businesses.
              </p>
              <h2 className="text-dark py-4 text-3xl lg:text-[42px] font-medium">
                $30
                <span className="text-body-color text-base font-medium text-gray-600">
                  {' '}
                  / month
                </span>
              </h2>

              <div className="text-gray-600 pt-4">
                <PlanFeatureItem entry="Unlimited apps" available={true} />
                <PlanFeatureItem
                  entry="Unlimited GPT-4 access"
                  available={true}
                />
                <PlanFeatureItem
                  entry="Unlimited data sources"
                  available={true}
                />
                <PlanFeatureItem entry="1000 entries/month" available={true} />
                <PlanFeatureItem
                  entry="Remove Rubber branding"
                  available={true}
                />
                {/*<PlanFeatureItem
                  entry="Password-Protected apps"
                  available={true}
                />*/}
              </div>
            </div>

            <Link
              href="/api/auth/signup?returnTo=/dashboard?flow=upgrade"
              className="mt-4 w-full text-center h-12 rounded-lg cursor-pointer hover:bg-[#489CF0] bg-[#2483E2] text-white flex items-center justify-center"
            >
              Purchase Now
            </Link>
          </PlanCard>

          <PlanCard>
            <div className="flex flex-col grow">
              <span className="text-primary mb-4 flex flex-row text-[#E29624] text-lg font-semibold items-center">
                <div className="h-6 w-6 mr-2 relative">
                  <Image
                    src="/assets/icons/ProYellowIcon.svg"
                    alt="Partner Plan Icon"
                    fill
                  />
                </div>
                Pro
              </span>
              <p className="text-gray-500 border-[#F2F2F2] pb-4 text-base">
                None of the plans fits your need? We still have you covered.
              </p>
              <h2 className="pb-2 text-3xl lg:text-[42px] font-medium">
                Custom
              </h2>

              <div className="text-gray-600 pt-4">
                <PlanFeatureItem
                  entry="Fine-tuned AI models"
                  available={true}
                />
                <PlanFeatureItem
                  entry="Custom data integrations"
                  available={true}
                />
                <PlanFeatureItem
                  entry="Custom volume pricing"
                  available={true}
                />
                <PlanFeatureItem entry="Dedicated hosting" available={true} />
              </div>
            </div>

            <Link href="mailto:derek@carbon.ai">
              <button className="cursor-pointer hover:bg-[#489CF0] bg-[#2483E2] mt-4 w-full text-white text-center h-12 rounded-lg">
                Contact Us
              </button>
            </Link>
          </PlanCard>
        </div>
        {/* <div className="-mx-4 flex flex-wrap justify-center">
          <div className="w-full px-4 md:w-1/2 lg:w-1/4">
            <div className="border-primary rounded-3xl shadow-pricing relative z-10 mb-10 overflow-hidden border border-opacity-20 bg-white py-10 px-8 lg:px-6 xl:p-12">
              <span className="flex flew-row text-primary mb-4 text-[#2D3C73] text-lg font-semibold">
                <img
                  className="h-6 mr-2"
                  src="/assets/icons/SmileFaceIcon.svg"
                  alt=""
                />
                Personal
              </span>
              <p className="text-gray-500 border-[#F2F2F2] pb-4 text-base">
                Perfect for personal and non-commerical use.
              </p>
              <h2 className="text-dark pb-2 text-[42px] font-medium">
                $0
                <span className="text-body-color text-base font-medium text-gray-600">
                  {' '}
                  / month{' '}
                </span>
              </h2>

              <div className="text-gray-600 pt-4 pb-9">
                <p className="flex flex-row py-1 text-body-color mb-1 text-base leading-2">
                  <img
                    className="h-6 mr-2"
                    src="/assets/icons/CheckIcon.svg"
                    alt=""
                  />
                  Create unlimited apps
                </p>
                <p className="flex flex-row py-1 text-body-color mb-1 text-base leading-2">
                  <img
                    className="h-6 mr-2"
                    src="/assets/icons/CheckIcon.svg"
                    alt=""
                  />
                  50 entries per month
                </p>
                <p className="flex flex-row py-1 text-body-color mb-1 text-base leading-2">
                  <img
                    className="h-6 mr-2"
                    src="/assets/icons/CheckIcon.svg"
                    alt=""
                  />
                  No custom data sources
                </p>
              </div>

              <Link href="/api/auth/signup?returnTo=/dashboard">
                <button className="cursor-pointer hover:bg-[#489CF0] bg-[#2483E2] mt-4 w-full text-white text-center h-12 rounded-lg">
                  Start Free
                </button>
              </Link>
            </div>
          </div>
          <div className="w-full px-4 md:w-1/2 lg:w-1/4">
            <div className="border-primary rounded-3xl shadow-pricing relative z-10 mb-10 overflow-hidden border border-opacity-20 bg-white py-10 px-8 sm:p-12 lg:py-10 lg:px-6 xl:p-12">
              <span className="flex flex-row text-primary mb-4 text-blue-500 text-lg font-semibold">
                <img
                  className="h-5 mt-0.5 mr-2"
                  src="/assets/icons/ProPlan.svg"
                  alt=""
                />
                Pro
              </span>
              <p className="text-gray-500 border-[#F2F2F2] pb-4 text-base">
                Perfect for client projects or small businesses.
              </p>
              <h2 className="text-dark pb-2 text-[42px] font-medium">
                $30
                <span className="text-body-color text-base font-medium text-gray-600">
                  {' '}
                  / month{' '}
                </span>
              </h2>

              <div className="text-gray-600 pt-4">
                <p className="flex flex-row py-1 text-body-color mb-1 text-base leading-2">
                  <img
                    className="h-6 mr-2"
                    src="/assets/icons/CheckIcon.svg"
                    alt=""
                  />
                  Create unlimited apps
                </p>
                <p className="flex flex-row py-1 text-body-color mb-1 text-base leading-2">
                  <img
                    className="h-6 mr-2"
                    src="/assets/icons/CheckIcon.svg"
                    alt=""
                  />
                  1K entries per month
                </p>
                <p className="flex flex-row py-1 text-body-color mb-1 text-base leading-2">
                  <img
                    className="h-6 mr-2"
                    src="/assets/icons/CheckIcon.svg"
                    alt=""
                  />
                  Unlimited data sources
                </p>
                <p className="flex flex-row py-1 text-body-color mb-1 text-base leading-2">
                  <img
                    className="h-6 mr-2"
                    src="/assets/icons/CheckIcon.svg"
                    alt=""
                  />
                  Password-Protected apps
                </p>
              </div>
              <Link href="https://buy.stripe.com/28oaIA3BP5wpgWk4gh">
                <button className="cursor-pointer hover:bg-[#489CF0] shadow-inner bg-[#2483E2] mt-4 w-full text-white text-center h-12 rounded-lg">
                  Purchase
                </button>
              </Link>
            </div>
          </div>
          <div className="w-full px-4 md:w-1/2 lg:w-1/4">
            <div className="border-primary rounded-3xl shadow-pricing relative z-10 mb-10 overflow-hidden border border-opacity-20 bg-white py-10 px-8 sm:p-12 lg:py-10 lg:px-6 xl:p-12">
              <span className="text-primary mb-4 flex flex-row text-[#E29624] text-lg font-semibold">
                <img
                  className="h-6 mt-0.5 mr-2"
                  src="/assets/icons/ProYellowIcon.svg"
                  alt=""
                />
                Partner
              </span>
              <p className="text-gray-500 border-[#F2F2F2] pb-4 text-base">
                None of the plans fits your need? We still have you covered.
              </p>
              <h2 className="pb-2 text-[42px] font-medium">Custom</h2>
              <Link href="mailto:derek@carbon.ai"></Link>
              <div className="text-gray-600 pt-4">
                <p className="flex flex-row py-1 text-body-color mb-1 text-base leading-2">
                  <img
                    className="h-6 mr-2"
                    src="/assets/icons/CheckIcon.svg"
                    alt=""
                  />
                  Fine-tuned AI models
                </p>
                <p className="flex flex-row py-1 text-body-color mb-1 text-base leading-2">
                  <img
                    className="h-6 mr-2"
                    src="/assets/icons/CheckIcon.svg"
                    alt=""
                  />
                  Custom data integrations
                </p>
                <p className="flex flex-row py-1 text-body-color mb-1 text-base leading-2">
                  <img
                    className="h-6 mr-2"
                    src="/assets/icons/CheckIcon.svg"
                    alt=""
                  />
                  Custom volume pricing
                </p>
                <p className="flex flex-row py-1 text-body-color mb-1 text-base leading-2">
                  <img
                    className="h-6 mr-2"
                    src="/assets/icons/CheckIcon.svg"
                    alt=""
                  />
                  Dedicated hosting
                </p>
              </div>
              <button className="cursor-pointer hover:bg-[#489CF0] bg-[#2483E2] shadow-inner mt-4 w-full text-white text-center h-12 rounded-lg">
                Contact Us
              </button>
            </div>
          </div>
        </div> */}
      </div>
    </section>
  );
}
