import {
  RocketLaunchIcon,
  CircleStackIcon,
  ComputerDesktopIcon,
  CpuChipIcon,
  UserCircleIcon,
  InboxIcon,
  CurrencyDollarIcon,
  BeakerIcon,
} from '@heroicons/react/24/outline';

const features = [
  /*{
    name: 'Full functionality, no code.',
    description:
      'Design and create interactive web applications via our simple drag-and-drop editor.',
    icon: ComputerDesktopIcon,
  },
  {
    name: 'Leverage the most advanced AI.',
    description:
      'Use the most advanced AI models in your app without worrying about API keys & integrations.',
    icon: CpuChipIcon,
  },
  {
    name: 'Robust, scalable infrastructure.',
    description:
      'Rubber handles all deployments and hosting of apps and AI models on your behalf.',
    icon: RocketLaunchIcon,
  },
  {
    name: 'Bring your own data.',
    description:
      'Connect your own data sources (via embeddings) to maximize the power of AI.',
    icon: CircleStackIcon,
  },*/
  {
    name: 'Lead Generation',
    description:
      'Build an AI-powered form to engage and capture more qualified, high-intent leads.',
    icon: InboxIcon,
  },
  {
    name: 'Customer Support',
    description:
      'Launch an AI chatbot trained on your Help Desk to answer questions and assist your customers.',
    icon: UserCircleIcon,
  },
  {
    name: 'Upsell Clients',
    description:
      'Use AI to increase the value of your service offering to existing and potential clients.',
    icon: CurrencyDollarIcon,
  },
  {
    name: 'Validate Product Ideas',
    description:
      'Quickly build and launch experiments to test the viability of features and products.',
    icon: BeakerIcon,
  },
];

export default function FeatureSection1() {
  return (
    <div className="bg-white py-24 sm:py-24" id="feature">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-md font-semibold leading-8 tracking-tight text-blue-600">
            Grow with AI
          </h2>
          {/*<p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Build and ship in minutes.
          </p>*/}
          <p className="mt-2 text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
            Drive business outcomes with AI
          </p>

          <p className="mt-6 text-lg leading-8 text-gray-600">
            Convert more leads, engage your audience, upsell existing services,
            deliver more value to your customers with AI.
          </p>
        </div>
        <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-4xl">
          <dl className="grid max-w-xl grid-cols-1 gap-y-10 gap-x-8 lg:max-w-none lg:grid-cols-2 lg:gap-y-16">
            {features?.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="text-base font-semibold leading-7 text-gray-900">
                  <div className="absolute top-0 left-0 flex h-10 w-10 items-center justify-center rounded-lg bg-[#2483E2]">
                    <feature.icon
                      className="h-5 w-5 text-white"
                      aria-hidden="true"
                    />
                  </div>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-base leading-7 text-gray-600">
                  {feature.description}
                </dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
