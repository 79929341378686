import {
  ComputerDesktopIcon,
  CloudArrowUpIcon,
  RocketLaunchIcon,
  LockClosedIcon,
  ServerIcon,
} from '@heroicons/react/20/solid';
import { WindowIcon } from '@heroicons/react/24/outline';
import Image from 'next/image';

const features = [
  {
    name: 'Push to deploy.',
    description: 'Publish Rubber apps anywhere with the click of a button.',
    icon: CloudArrowUpIcon,
  },
  {
    name: 'Robust, scalable infrastructure.',
    description:
      'Rubber handles deployments and hosting of all AI models and apps.',
    icon: RocketLaunchIcon,
  },
  {
    name: 'Whitelist our apps.',
    description: 'Remove Rubber branding from any published apps.',
    icon: ComputerDesktopIcon,
  },
  {
    name: 'Embed anywhere.',
    description: 'Add AI functionality to any websites and apps with Rubber.',
    icon: WindowIcon,
  },
];

export default function CalloutSection3() {
  return (
    <div className="overflow-hidden bg-gray-100 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-blue-600">
                Deploy in minutes
              </h2>
              <p className="mt-2 text-3xl font-semibold leading-10 text-gray-900">
                Publish and share anywhere.
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Easily share Rubber apps and tools with all your customers and
                team members.
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features?.map((feature, index) => (
                  <div key={index} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon
                        className="absolute top-1 left-1 h-5 w-5 text-blue-600"
                        aria-hidden="true"
                      />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <Image
            src="/assets/images/stats.jpg"
            alt="Product screenshot"
            className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
            width={2432}
            height={1442}
          />
        </div>
      </div>
    </div>
  );
}
