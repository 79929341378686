import {
  CloudArrowUpIcon,
  LockClosedIcon,
  ServerIcon,
  CodeBracketIcon,
} from '@heroicons/react/20/solid';
import Image from 'next/image';

const features = [
  {
    name: 'Connect your data.',
    description:
      'Upload a file containing your data for the AI to use. We currently support .txt, .csv, and .pdf file formats. Stay tuned for more supported file types in the future.',
    icon: CloudArrowUpIcon,
  },
  {
    name: 'Add AI to anything.',
    description:
      'Rubber connects AI models to your data automatically, allowing you to query and transform it with no additional work.',
    icon: CodeBracketIcon,
  },
];

export default function CalloutSection2() {
  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-y-16 gap-x-8 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-blue-600">
                Simple yet Powerful
              </h2>
              <p className="mt-2 text-3xl font-semibold leading-10 text-gray-900">
                Connect to anything.
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Rubber empowers businesses and individuals to seamlessly use AI
                across all your data sources.
              </p>
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {features?.map((feature, index) => (
                  <div key={index} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      <feature.icon
                        className="absolute top-1 left-1 h-5 w-5 text-blue-600"
                        aria-hidden="true"
                      />
                      {feature.name}
                    </dt>{' '}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <Image
            src="/assets/images/datasources.png"
            alt="Product screenshot"
            className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
            width={2432}
            height={1442}
          />
        </div>
      </div>
    </div>
  );
}
