import Image from 'next/image';
import Link from 'next/link';

export default function Footer() {
  return (
    <footer
      className="px-4 py-8 bg-white rounded-lg shadow-lg md:px-60 md:py-10"
      id="footer"
    >
      <div className=" sm:flex sm:items-center sm:justify-between">
        <Image priority src="/assets/icons/Logo.svg" height={0} width={100} />

        <ul className="flex flex-wrap items-center mb-6 text-sm text-gray-800 pt-4 sm:mb-0 ">
          {/*<li>
                        <a href="#" className="mr-4 hover:underline md:mr-6 ">About</a>
                    </li>*/}

          <li>
            <Link
              href="https://blemish.notion.site/Privacy-Policy-dec7566b193c4379923921b1e12965ca"
              className="cursor-pointer mr-4 hover:underline md:mr-6"
            >
              Privacy Policy
            </Link>
          </li>
          <li>
            <Link
              href="https://www.notion.so/blemish/Terms-of-Service-b311dd2cfb5a4ed69ddfea5bad39b25e"
              className="cursor-pointer mr-4 hover:underline md:mr-6 "
            >
              Terms of Service
            </Link>
          </li>
          <li>
            <Link
              href="mailto:team@carbon.ai"
              className="cursor-pointer hover:underline"
            >
              Contact
            </Link>
          </li>
        </ul>
      </div>
      <hr className="my-6 border-gray-300 sm:mx-auto lg:my-8" />
      <span className="block text-sm text-gray-500 sm:text-center">
        ©
        <a href="https://www.rubber.ai/" className="hover:underline">
          JCDT Inc DBA Rubber
        </a>
        . All Rights Reserved.
      </span>
    </footer>
  );
}
