import {
  RocketLaunchIcon,
  CircleStackIcon,
  ComputerDesktopIcon,
  CpuChipIcon,
  LinkIcon,
} from '@heroicons/react/24/outline';
import Image from 'next/image';
import Link from 'next/link';
import DemoPopupModal from './DemoPopupModal';

const features = [
  {
    name: 'Full functionality, no code.',
    description:
      'Use pre-built components and templates to build anything you want.',
    icon: ComputerDesktopIcon,
  },
  {
    name: 'Chain prompts with ease.',
    description:
      'Create complex workflows with multiple prompts and models with ease.',
    icon: LinkIcon,
  },
  {
    name: 'Leverage the most advanced AI.',
    description:
      'Use the most advanced AI models in your app without worrying about API keys & integrations.',
    icon: CpuChipIcon,
  },
];

export default function CalloutSectionWithChat() {
  return (
    <div className="overflow-hidden bg-white lg:px-12 xl:px-32 py-8 sm:py-16">
      <div className="px-6 lg:px-8">
        {/*<div className="text-center justify-center flex flex-row lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 ">
                What is Rubber?
              </h2>
              <p className="mt-2 text-3xl font-semibold leading-10 text-gray-900">
                A complete set of blocks to build powerful AI apps.
              </p>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Rubber lets you create simple and intuitive apps and tools
                powered by cutting-edge AI models, like GPT 4.
              </p>
              {/*<dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                  {features?.map((feature, index) => (
                    <div key={index} className="relative pl-9">
                      <dt className="inline font-semibold text-gray-900">
                        <feature.icon
                          className="absolute top-1 left-1 h-5 w-5 "
                          aria-hidden="true"
                        />
                        {feature.name}
                      </dt>{' '}
                      <dd className="inline">{feature.description}</dd>
                    </div>
                  ))}
                  </dl>
            </div>
          </div>*/}

        <div className="md:grid md:grid-cols-2 flex flex-col mx-8 xl:mx-32 gap-y-4 gap-x-10">
          <div className="bg-white shadow-md border col-span-1 flex flex-col mx-auto text-center md:h-[22em] lg:h-90 xl:h-96 2xl:h-[30em] w-full rounded-lg">
            <div className="text-left overflow-hidden py-6 px-10">
              <p className="text-sm text-blue-600 font-medium pb-1">
                Custom UI
              </p>
              <h2 className="font-semibold text-xl">
                Create your own AI experience.
              </h2>
              <p className="mt-1 text-[16px] text-gray-600 mb-2">{`Build a custom UI (without code) for users to interact with AI and embed on any website.`}</p>
              {/*<Link href="/" className="cursor-pointer font-medium text-sm">View Demo →</Link>*/}
              <DemoPopupModal
                style="hover:translate-x-1 text-sm font-medium cursor-pointer leading-7 text-gray-900"
                cta="View Demo →"
                url="https://www.loom.com/embed/63b7f139206b4eb2a8811dd5f2154fae"
              />
              <Image
                src="/assets/images/app.png"
                alt="Product screenshot"
                className="w-full mt-4 lg:mt-6 max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 md:-mb-4"
                width={2000}
                height={1000}
              />
            </div>
          </div>
          <div className="bg-white shadow-md border col-span-1 flex flex-col mx-auto text-center md:h-[22em] lg:h-90 xl:h-96 2xl:h-[30em] w-full rounded-lg">
            <div className="text-left overflow-hidden py-6 px-10">
              <p className="text-sm text-blue-600 font-medium pb-1">Chatbots</p>
              <h2 className="font-semibold text-xl">
                Build your own ChatGPT chatbot.
              </h2>
              <p className="mt-1 text-[16px] text-gray-600 mb-2">
                Train ChatGPT on your data and add it to your website as a
                chatbot.
              </p>
              <DemoPopupModal
                style="hover:translate-x-1 text-sm font-medium cursor-pointer leading-7 text-gray-900"
                cta="View Demo →"
                url="https://www.loom.com/embed/6b9cc550f9564250aa66d77341f3bd9d"
              />{' '}
              <Image
                src="/assets/images/chat.png"
                alt="Product screenshot"
                className="w-2/3 mt-4 lg:w-1/2 lg:mt-6 bg-transparent max-w-none mx-auto shadow-md rounded-[17px] ring-gray-400/10 -mb-2 lg:-mb-4"
                width={1000}
                height={1200}
              />
            </div>
          </div>
        </div>

        {/*<Image
              src="/assets/images/workflow-1.png"
              alt="Product screenshot"
              className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
              width={2432}
              height={1442}
                  />*/}
      </div>
    </div>
  );
}
