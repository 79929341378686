import { CheckIcon } from '@heroicons/react/24/outline';
import Image from 'next/image';
import Link from 'next/link';

export default function TestimonialSection() {
  return (
    <section className="relative pt-24 pb-32 bg-gray-100 overflow-hidden">
      <img
        className="absolute top-0 left-0"
        src="flaro-assets/images/testimonials/gradient.svg"
        alt=""
      />
      <img
        className="absolute bottom-0 right-0"
        src="flaro-assets/images/testimonials/gradient2.svg"
        alt=""
      />
      <div className="relative z-10 container px-4 mx-auto">
        <h2 className="justify-center pb-2 text-center text-md font-semibold leading-8 tracking-normal text-blue-600">
          Loved Around the World
        </h2>
        <h2 className="mb-16 text-4xl font-semibold font-heading text-center tracking-px-n leading-normal">
          Don&apos;t just take our word for it
        </h2>
        <div className="flex flex-wrap -m-3">
          <div className="w-full md:w-1/2 lg:w-1/3 p-3">
            <div className="p-6 h-full bg-white bg-opacity-60 border rounded-xl">
              <div className="flex flex-col justify-between h-full">
                <div className="mb-5 block">
                  <div className="flex flex-wrap mb-4 -m-2">
                    <div className="w-auto p-2">
                      <Image
                        priority
                        src="/assets/images/jeremy.jpeg"
                        height={45}
                        width={45}
                        className="mx-auto rounded-full"
                        alt="Jeremy Cai"
                      />
                    </div>
                    <div className="w-auto p-2">
                      <h3 className="font-semibold leading-normal">
                        Jeremy Cai
                      </h3>
                      <p className="text-gray-500">@jjeremycai</p>
                    </div>
                  </div>
                  <p className="text-md font-medium">
                    {`If you haven&rsquo;t tried out Rubber yet, I would
                    definitely recommend it. We've quickly built a bunch of AI
                    tools for my company Italic.com, from writing product
                    descriptions to TikTok influencers scripts.`}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 lg:w-1/3 p-3">
            <div className="p-6 h-full bg-white bg-opacity-60 border rounded-xl">
              <div className="flex flex-col justify-between h-full">
                <div className="mb-5 block">
                  <div className="flex flex-wrap mb-4 -m-2">
                    <div className="w-auto p-2">
                      <Image
                        priority
                        src="/assets/images/michael.jpeg"
                        height={45}
                        width={45}
                        className="mx-auto rounded-full"
                        alt="Michael Tu"
                      />
                    </div>
                    <div className="w-auto p-2">
                      <h3 className="font-semibold leading-normal">
                        Michael Tu
                      </h3>
                      <p className="text-gray-500">@MichaelTu_2</p>
                    </div>
                  </div>
                  <p className="text-md font-medium">
                    {`I spent a day building an essay writer app for students
                    using Rubber and am now earning $5k/mo from selling monthly
                    subscriptions for access to it. I'm a Rubber customer for
                    life.`}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 lg:w-1/3 p-3">
            <div className="p-6 h-full bg-white bg-opacity-60 border rounded-xl">
              <div className="flex flex-col justify-between h-full">
                <div className="mb-5 block">
                  <div className="flex flex-wrap mb-4 -m-2">
                    <div className="w-auto p-2">
                      <Image
                        priority
                        src="/assets/images/daniel.jpeg"
                        height={45}
                        width={45}
                        className="mx-auto rounded-full"
                        alt="Daniel Gulati"
                      />
                    </div>
                    <div className="w-auto p-2">
                      <h3 className="font-semibold leading-normal">
                        Daniel Gulati
                      </h3>
                      <p className="text-gray-500">@DanielGulati</p>
                    </div>
                  </div>
                  <p className="text-md font-medium">
                    As an investor, many of my companies ask how they can start
                    working with AI. My recommendation is always to start
                    buiding with Rubber.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 lg:w-1/3 p-3">
            <div className="p-6 h-full bg-white bg-opacity-60 border rounded-xl">
              <div className="flex flex-col justify-between h-full">
                <div className="mb-5 block">
                  <div className="flex flex-wrap mb-4 -m-2">
                    <div className="w-auto p-2">
                      <Image
                        priority
                        src="/assets/images/zay.jpg"
                        height={45}
                        width={45}
                        className="mx-auto rounded-full"
                        alt="Zaylan Jacobsen"
                      />
                    </div>
                    <div className="w-auto p-2">
                      <h3 className="font-semibold leading-normal">
                        Zaylan Jacobsen
                      </h3>
                      <p className="text-gray-500 uppercase">@zj</p>
                    </div>
                  </div>
                  <p className="text-md font-medium">
                    Rubber brings together the best of no-code with AI to give
                    anyone the power to use AI! I used it to create a cocktail
                    generator app that I can share with friends and plan to
                    offer Rubber development as a service of my software
                    development agency! Awesome tool.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 lg:w-1/3 p-3">
            <div className="p-6 h-full bg-white bg-opacity-60 border rounded-xl">
              <div className="flex flex-col justify-between h-full">
                <div className="mb-5 block">
                  <div className="flex flex-wrap mb-4 -m-2">
                    <div className="w-auto p-2">
                      <Image
                        priority
                        src="/assets/images/lillie.jpg"
                        height={45}
                        width={45}
                        className="mx-auto rounded-full"
                        alt="Lillie Sun"
                      />
                    </div>
                    <div className="w-auto p-2">
                      <h3 className="font-semibold leading-normal">
                        Lillie Sun
                      </h3>
                      <p className="text-gray-500">@lilliesun</p>
                    </div>
                  </div>
                  <p className="text-md font-medium">
                    {`My favorite part of Rubber is how this is really
                    democratizing AI utilization for everyone. Especially those
                    outside of the tech bubble, new tech can feel intimidating
                    but Rubber feels approachable, intuitive and enables people
                    to build anything they're inspired by (e.g. me building an
                    app to generate text responses after dates)`}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2 lg:w-1/3 p-3">
            <div className="p-6 h-full bg-white bg-opacity-60 border rounded-xl">
              <div className="flex flex-col justify-between h-full">
                <div className="mb-5 block">
                  <div className="flex flex-wrap mb-4 -m-2">
                    <div className="w-auto p-2">
                      <Image
                        priority
                        src="/assets/images/kyle.jpg"
                        height={45}
                        width={45}
                        className="mx-auto rounded-full"
                        alt="Kyle Lawson"
                      />
                    </div>
                    <div className="w-auto p-2">
                      <h3 className="font-semibold leading-normal">
                        Kyle Lawson
                      </h3>
                      <p className="text-gray-500 uppercase">@klawson3</p>
                    </div>
                  </div>
                  <p className="text-md font-medium">
                    Rubber helps me generate more qualified leads for my
                    business by allowing me to use AI to better engage my
                    audience. Also their customer service is blazing fast -
                    their CEO still answers all my emails and live chats.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mx-auto max-w-md text-center pt-12 lg:mx-0 lg:flex-auto lg:pt-24 lg:text-left">
          <h2 className="justify-start pb-2 text-center lg:text-left text-md font-semibold leading-8 tracking-tight text-blue-600">
            Try Rubber Today
          </h2>
          <h2 className="text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
            Get started buiding AI apps and tools
            <br />
            <span className="text-[#2483E2]">in under 10 minutes.</span>
          </h2>

          <div className="mt-10 flex items-center justify-center gap-x-6 lg:justify-start cursor-pointer">
            <Link
              href="/api/auth/signup?returnTo=/dashboard"
              className="rounded-lg bg-[#2483E2] px-4 py-2.5 text-sm font-semibold leading-7 text-white hover:bg-[#489CF0] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
              Sign Up for Free
            </Link>
            <Link
              href="https://calendly.com/hubbleai/30-minute-meeting"
              className="text-base font-semibold leading-7 text-gray-800"
            >
              Book a Demo <span aria-hidden="true">→</span>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}
