import {
  CalendarDaysIcon,
  HandRaisedIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline';
import { useState } from 'react';
import axios from 'axios';
import React from 'react';

export default function NewsletterSection() {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [showSubscriptionResponse, setShowSubscriptionResponse] =
    useState(false);

  const handleSubscribe = async (e) => {
    e.preventDefault();
    const isEmailValid = String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    if (!isEmailValid) {
      setEmailError(true);
      return;
    }

    // Write the email address to supabase!
    const appDataResponse = await axios.post('/api/supabase/insertEmail', {
      email: email,
      environment: process.env.NEXT_PUBLIC_ENVIRONMENT,
    });

    setShowSubscriptionResponse(true);
  };
  return (
    <div
      className="relative overflow-hidden bg-black py-16 sm:py-24 lg:py-24"
      id="newsletter"
    >
      <div className="mx-auto px-14 lg:px-8">
        <div className="justify-center grid gap-y-16 gap-x-8 lg:max-w-none">
          <div className="max-w-2xl">
            <h2 className="text-xl text-center font-semibold tracking-tight text-white sm:text-4xl">
              Join our next AI Masterclass.
            </h2>
            <p className="mt-4 text-center text-md sm:text-lg leading-8 text-white">
              {`New program cohorts start weekly. Join our waitlist and save 50% off the program ($199 one-time fee).`}
            </p>
            {showSubscriptionResponse ? (
              <h3 className="mt-6 flex max-w-full flex-col text-white font-semibold">
                You have successfully subscribed to our newsletter!
              </h3>
            ) : (
              <form className="mt-6 flex max-w-full flex-col">
                <div className="flex gap-x-2 max-w-full">
                  <label htmlFor="email-address" className="sr-only">
                    Email address
                  </label>
                  <div className="grow flex flex-row rounded-md border border-black/20 bg-white/20 px-[calc(theme(spacing.4)-1px)] py-[calc(theme(spacing[1.5])-1px)] text-base leading-7 text-gray-700 placeholder-gray-500 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 items-center">
                    <input
                      id="email-address"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="grow placeholder-gray-500 border-0 focus:outline-none outline-none focus:outline-0 outline-0"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {emailError && (
                      <div className="w-6">
                        <ExclamationTriangleIcon className="w-6 h-6 text-red-500" />
                      </div>
                    )}
                  </div>

                  <button
                    onClick={handleSubscribe}
                    type="submit"
                    className="cursor-pointer px-3 py-2.5 flex-none rounded-lg bg-[#2483E2] shadow-inner text-base font-semibold leading-7 text-white hover:bg-[#489CF0] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                  >
                    Subscribe
                  </button>
                </div>
                {emailError && (
                  <h1 className="mt-2 text-red-500 font-bold text-sm">
                    Please enter a valid email address!
                  </h1>
                )}
              </form>
            )}
            {/* <div className="klaviyo-form-WfYrZE"></div> */}
          </div>
          {/*<dl className="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 lg:pt-2">
            <div className="flex flex-col items-start">
              <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                <CalendarDaysIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <dt className="mt-4 font-semibold text-white">Weekly articles</dt>
              <dd className="mt-2 leading-7 text-gray-400">
                Non laboris consequat cupidatat laborum magna. Eiusmod non irure cupidatat duis commodo amet.
              </dd>
            </div>
            <div className="flex flex-col items-start">
              <div className="rounded-md bg-white/5 p-2 ring-1 ring-white/10">
                <HandRaisedIcon className="h-6 w-6 text-white" aria-hidden="true" />
              </div>
              <dt className="mt-4 font-semibold text-white">No spam</dt>
              <dd className="mt-2 leading-7 text-gray-400">
                Officia excepteur ullamco ut sint duis proident non adipisicing. Voluptate incididunt anim.
              </dd>
            </div>
  </dl>*/}
        </div>
      </div>
      <svg
        className="absolute top-0 left-1/2 -z-10 h-[42.375rem] -translate-x-1/2 blur-3xl xl:-top-6"
        viewBox="0 0 1155 678"
        fill="none"
      >
        <path
          fill="url(#09dbde42-e95c-4b47-a4d6-0c523c2fca9a)"
          fillOpacity=".3"
          d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
        />
        <defs>
          <linearGradient
            id="09dbde42-e95c-4b47-a4d6-0c523c2fca9a"
            x1="1155.49"
            x2="-78.208"
            y1=".177"
            y2="474.645"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#9089FC" />
            <stop offset={1} stopColor="#FF80B5" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
